
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "@comp/left_menu.vue";
import PublicHeader from "@comp/public_header.vue";
export default {
    name: "",
    components: {
        LeftMenu,
        PublicHeader,
    },
    props: {

    },
    data() {
        return {
            systems: [],
            menus: [
                {
                    id: "303",
                    name: "预警预案",
                    routerName: "warning_plan",
                    className: "icon-yujingyuan-01",
                },
                {
                    id: "304",
                    name: "预警对象设置",
                    routerName: "warning_object_set",
                    className: "icon-yichanyujing-01",
                },
                {
                    id: "305",
                    name: "预警配置",
                    routerName: "warning_config",
                    className: "icon-yujingshezhi-01",
                },
            ],
            defaultIndex: 0,
            mainMenuIndex: "30",
        };
    },
    computed: {
    },
    watch: {

    },
    methods: {

    },
};
<template>
    <div id="distribution">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                class="left-menu"
                :menus="menus"
                :defaultIndex="defaultIndex"
                :mainMenuIndex="mainMenuIndex"
            ></left-menu>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "distribution",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
#distribution {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 77px);
        background: #eaece6;
        .public-header {
            flex: none;
        }
    }
}
</style>
